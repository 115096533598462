import React from 'react'
import '../css/header.css'

const Alert = () => {

   return(
    <div className="nav-alert min-h-10 relative bg-primary py-2 z-50">
      <div className="container alert-content ">
        <p className='pl-3'>Download our sales sheet and more <a className="alert-link" href='/documents'>here!</a></p>
      </div>
    </div>
   )

}


export default Alert